<template>
  <div class="about">
    <h1>About Jab Finder</h1>
    <p><strong>Jabfinder</strong> is here to help you find Pfizer vaccine availability at your nearest clinic. <br><br>
      We initially developed this to book vaccines for our brother and elderly parents <br>
      and thought it would help others if we make this Public.<br>
      Please get vaccinated and keep everyone safe.  <br><br>
    If you have any question, feel free to contact us via email <br>
      <a :href='"mailto:"+email'>{{ email }}</a> </p>
    <v-btn
      color="success"
      v-clipboard="email"
    >Copy Email</v-btn>
  </div>
</template>

<script>

export default {
  data () {
    return {
      email: 'hello@jabfinder.com.au'
    }
  }
}
</script>
